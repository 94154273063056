<template>
  <v-card class="elevation-0">
    <div>
      <div class="row-title-btn">
        <div class="controls-actions d-flex justify-end">
          <v-btn @click="openDialog" icon class="ml-2">
            <v-icon size="24">{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="lotsAuction"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height elevation-3"
      fixed-header
      disable-sort
    >
      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <CrudButtons @edit="openEdit(item)" @remove="openRemove(item)"></CrudButtons>
        </div>
      </template>
    </v-data-table>
    <WDialog width="600" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormLotsAuction
          v-if="dialog"
          :loading="loading"
          :lotAuctionEdited="lotAuctionEdited"
          :districts="districts"
          :idCategoryAuction="idCategoryAuction"
          :idAuction="idAuction"
          @beforeUpdate="updateLotAuction"
          @beforeCreate="createLotAuction"
        ></FormLotsAuction>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import CrudButtons from '@/components/buttons/CrudButtons.vue'
import FormLotsAuction from '@/components/forms/FormLotsAuction.vue'
import useLotsAuction from '@/composables/useLotsAuction'
import useDistricts from '@/composables/useDistricts'
import WDialog from '@/components/dialogs/WDialog.vue'

export default {
  props: {
    idAuction: String,
    idCategoryAuction: String,
  },
  components: {
    CrudButtons,
    WDialog,
    FormLotsAuction,
  },
  setup(props) {
    const {
      dialog,
      lotAuctionEdited,
      lotsAuction,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createLotAuction,
      updateLotAuction,
    } = useLotsAuction(props.idAuction)
    const { districts } = useDistricts()
    return {
      icons: {
        mdiPlus,
      },
      dialog,
      lotAuctionEdited,
      lotsAuction,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createLotAuction,
      updateLotAuction,
      // districts
      districts,
      headers:
        props.idCategoryAuction == "1"
          ? [
              { text: 'DISTRITO', value: 'districts.name' },
              { text: 'UBICACIÓN', value: 'address' },
              { text: 'PARTIDA', value: 'start' },
              { text: 'ÁREA', value: 'area' },
              { text: 'VC', value: 'vc' },
              { text: 'GARANTÍA', value: 'warranty' },
              { text: '', value: 'actions', align: 'right' },
            ]
          : [
              { text: 'DISTRITO', value: 'districts.name' },
              { text: 'UBICACIÓN', value: 'address' },
              { text: 'ÚNICO USO', value: 'single_use' },
              { text: 'ÁREA', value: 'area' },
              { text: 'RENTA', value: 'rent' },
              { text: 'RENTA ADELANTADA', value: 'advance_rent' },
              { text: 'PUJA', value: 'bid' },
              { text: 'GARANTÍA', value: 'warranty' },
              { text: '', value: 'actions', align: 'right' },
            ],
    }
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #00aca0;
  padding: 10px;
  margin: 10px;
}

.icon-service img {
  max-width: 50px;
  max-height: 50px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>