<template>
  <v-row>
    <v-col cols="12">
      <h3>Contenido</h3>
    </v-col>

    <v-col v-for="item in contentAuction" :key="item.id" cols="12" md="6">
      <div class="d-flex justify-space-between">
        <div>
          <label for="title_auctions_contents">Subtítulo</label>
          <v-text-field
            id="title_auctions_contents"
            v-model="item.title_auctions_contents"
            outlined
            dense
            placeholder="Subtítulo"
            :rules="[v => !!v || 'Requerido']"
            :error-messages="errorMessage"
          ></v-text-field>
        </div>
        <div>
          <label for="register_url">Ícono</label>
          <v-file-input
            id="file_cover_page"
            v-model="item.image"
            outlined
            prepend-icon=""
            dense
            placeholder="Elige una imagen"
            @change="onFileChange(item)"
          ></v-file-input>
        </div>

        <div>
          <v-btn @click="saveForm(item)" class="mx-2" fab small :disabled="loading" :loading="loading" color="primary">
            <v-icon>{{ icons.mdiContentSave }}</v-icon>
          </v-btn>
          <v-btn @click="removeContentAuction(item)" fab small :disabled="loading" :loading="loading" outlined>
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </div>
      </div>
      <div>
        <h3 class="subtitle-content d-flex align-center">
          <img
            class="image-content"
            draggable="false"
            :src="item.url || loadImage(item.icon_auctions_contents)"
            :alt="item.title_auctions_contents"
          />
          <span class="pl-2"> {{ item.title_auctions_contents }} </span>
        </h3>
      </div>
      <label>Contenido</label>
      <v-md-editor class="mb-4" v-model="item.content_auctions_contents" height="300px"></v-md-editor>
    </v-col>
    <v-col class="d-flex align-center justify-center" cols="12" md="6">
      <v-btn fab :disabled="loading" :loading="loading" color="primary" @click="setNewContentAuction">
        <v-icon>{{ icons.mdiPlus }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import useAuctionContent from '@/composables/useAuctionContent'
import { mdiContentSave, mdiPlus, mdiClose } from '@mdi/js'
import { loadAPIImage } from '@/utils/images'

export default {
  props: {
    idAuction: String,
  },

  setup(props) {
    let errorMessage = ''
    const saveForm = async item => {
      if (!item.title_auctions_contents) {
        // console.log('hola')
        errorMessage = 'Debe añadir el subtítulo'
        return
      }
      if (!item.id && !item.image) {
        errorMessage = 'Debe añadir el subtítulo'
        return
      }
      await saveContentAuction(item)
    }
    const {
      contentAuction,
      loading,
      setNewContentAuction,
      onFileChange,
      // methods
      saveContentAuction,
      removeContentAuction,
    } = useAuctionContent(props.idAuction)
    return {
      errorMessage,
      icons: {
        mdiContentSave,
        mdiPlus,
        mdiClose,
      },
      contentAuction,
      loading,
      setNewContentAuction,
      // methods
      saveContentAuction,
      removeContentAuction,
      onFileChange,
      saveForm,
      loadImage(src) {
        return loadAPIImage(src)
      },
    }
  },
}
</script>

<style>
.image-content {
  max-width: 25px;
  min-width: 25px;
}
</style>