<template>
  <v-card>
    <FormAuction
      :loading="loading"
      :getAuctionById="getOneAuction"
      @beforeUpdate="updateAuction"
      @beforeCreate="createAuction"
      :idAuction="idAuction"
      :idCategoryAuction="idCategory"
    ></FormAuction>
  </v-card>
</template>

<script>
import FormAuction from '@/components/forms/FormAuction.vue'
import useAuctionEdited from '@/composables/useAuctionEdited'

export default {
  components: {
    FormAuction,
  },
  setup(props, context) {
    const router = context.root.$router
    const route = context.root.$route
    const idCategory = route.params.idCategory
    const idAuction = route.params.idAuction
    // Composition API
    const {
      loading,
      // crud
      getOneAuction,
      createAuction,
      updateAuction,
    } = useAuctionEdited(idCategory, idAuction, router)

    return {
      idAuction,
      loading,
      idCategory,
      idAuction,
      // crud
      getOneAuction,
      createAuction,
      updateAuction,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
