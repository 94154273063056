<template>
  <div>
    <div class="mb-2">
      <div class="row-title-btn">
        <div class="controls-actions d-flex justify-end">
          <v-btn @click="openDialog" icon class="ml-2">
            <v-icon size="24">{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-data-iterator :items="someAuctions" :items-per-page="10" :page="1">
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.id" cols="12" sm="6" lg="3" md="6">
            <CardSomeAuctions
              :item="item"
              :srcImage="loadImage(item.img_some_auctions)"
              :idAuction="idAuction"
              @edit="openEdit(item)"
              @remove="removeSomeAuctions(item)"
            ></CardSomeAuctions>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <WDialog width="500" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormSomeAuctions
          :districts="districts"
          v-if="dialog"
          :loading="loading"
          :idAuction="idAuction"
          :payloadEdited="someAuctionEdited"
          @beforeUpdate="updateSomeAuctions"
          @beforeCreate="createSomeAuctions"
        ></FormSomeAuctions>
      </template>
    </WDialog>
  </div>
</template>

<script>
import CardSomeAuctions from '@/components/cards/CardSomeAuctions.vue'
import FormSomeAuctions from '@/components/forms/FormSomeAuctions.vue'
import WDialog from '@/components/dialogs/WDialog.vue'

import useSomeAuctions from '@/composables/useSomeAuctions'
import useDistricts from '@/composables/useDistricts'

import { loadAPIImage } from '@/utils/images'
import { mdiPlus } from '@mdi/js'
export default {
  components: {
    CardSomeAuctions,
    FormSomeAuctions,
    WDialog,
  },
  props: {
    idAuction: String,
  },
  setup(props) {
    const {
      someAuctions,
      loading,
      dialog,
      someAuctionEdited,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      createSomeAuctions,
      updateSomeAuctions,
      removeSomeAuctions,
      // methods
    } = useSomeAuctions(props.idAuction);

    const { districts } = useDistricts();

    return {
      icons: { mdiPlus },
      someAuctions,
      loading,
      dialog,
      someAuctionEdited,
      districts,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      //crud
      createSomeAuctions,
      updateSomeAuctions,
      removeSomeAuctions,
      loadImage(src) {
        return loadAPIImage(src)
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
