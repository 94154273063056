import { ref } from '@vue/composition-api';
import store from '@/store/index';

const useAuctionContent = (idAuction) => {
  // base data
  const contentAuction = ref([]);
  const loading = ref(false);

  const setNewContentAuction = () => {
    const newItem = {
      image: null,
      id: null,
      content_auctions_contents: "",
      title_auctions_contents: "",
      id_auctions: idAuction
    };
    contentAuction.value.push(newItem);
  }

  const onFileChange = (item) => {
    contentAuction.value.map((ca) => {
      if (ca == item) {
        ca.url = URL.createObjectURL(item.image)

      }
    })
  }

  // methods
  const getContentByAuction = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch('fetchContentByAuction', idAuction);
    loading.value = false;
    if (status != 200) return;
    contentAuction.value = data;
  }

  const saveContentAuction = async (item) => {
    // console.log(item)
    loading.value = true;
    if (!item.id) {
      const { data, status } = await store.dispatch("createContentAuction", item);
      if (status != 200 && status != 201) return;
    } else {
      const { data, status } = await store.dispatch("updateContentAuction", item);
      if (status != 200 && status != 201) return;
    }
    loading.value = false;
    await getContentByAuction();
  }

  const removeContentAuction = async (item) => {
    if (!item.id) {
      const foundedIdx = contentAuction.value.findIndex(ca => ca == item);
      // console.log(foundedIdx)
      contentAuction.value.splice(foundedIdx, 1)
      return;
    }
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeContentAuction", item)
    if (status != 200 && status != 201 && status != 204) return;
    await getContentByAuction();
  }


  getContentByAuction();

  return {
    contentAuction,
    loading,
    setNewContentAuction,
    onFileChange,
    // methods
    saveContentAuction,
    removeContentAuction
  };
};

export default useAuctionContent;
