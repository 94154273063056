<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formLotAuction" @submit.prevent="saveForm">
        <v-row v-if="idCategoryAuction == 1">
          <v-col cols="12" md="3">
            <label for="id_districts">Distrito</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-autocomplete
              id="id_districts"
              v-model="lotAuction.id_districts"
              outlined
              dense
              placeholder="Distrito"
              item-text="name"
              item-value="id"
              :items="districts"
              :rules="required"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="3">
            <label for="address">Dirección</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              autocomplete="off"
              id="address"
              v-model="lotAuction.address"
              outlined
              dense
              placeholder="Ingresa la Dirección"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="location">Buscar Ubicación</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-autocomplete
              id="location"
              v-model="selectedPlace"
              outlined
              dense
              placeholder="Ingresa la dirección"
              :loading="loadingFindAddress"
              :search-input.sync="findAddressInPlaces"
              :items="foundedAddress"
              item-text="description"
              item-value="place_id"
              :filter="filterObject"
              clearable
              return-object
              @change="getLocationByPlaceId"
              @click:clear="clearMap"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <span>Señala el lote a subastar</span>
          </v-col>

          <v-col cols="12" md="12" class="mb-2">
            <GmapMap @click="addPath" :center="center" :zoom="zoomMap" style="width: 100%; height: 400px">
              <GmapMarker v-if="location" :position="location" />
              <GmapPolygon
                v-if="lotAuction.path.length > 0"
                :path="lotAuction.path"
                :options="{ strokeColor: 'red' }"
              />
            </GmapMap>
          </v-col>

          <v-col cols="12" class="mb-4">
            <v-row>
              <v-col v-for="(item, idx) in lotAuction.path" :key="idx" cols="4" class="pa-1">
                <v-card elevation="1">
                  <div class="d-flex align-center">
                    <v-card-text class="pa-2 caption"
                      >{{ idx + 1 }}. {{ String(item.lat).substr(0, 7) }},
                      {{ String(item.lng).substr(0, 7) }}</v-card-text
                    >
                    <v-btn @click="removePath(idx, item)" small icon>
                      <v-icon small>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <label for="start">Partida</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              autocomplete="off"
              id="start"
              v-model="lotAuction.start"
              outlined
              dense
              placeholder="Partida"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="area">Área (m2)</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="area"
              autocomplete="off"
              v-model="lotAuction.area"
              outlined
              dense
              placeholder="Área"
              :rules="required"
              type="number"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="vc">VC S/.</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              autocomplete="off"
              type="number"
              id="vc"
              v-model="lotAuction.vc"
              outlined
              dense
              placeholder="VC"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="warranty">Garantía S/.</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              autocomplete="off"
              type="number"
              id="warranty"
              v-model="lotAuction.warranty"
              outlined
              dense
              placeholder="Garantía"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" md="3">
            <label for="id_districts">Distrito</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-autocomplete
              id="id_districts"
              v-model="lotAuction.id_districts"
              outlined
              dense
              placeholder="Distrito"
              item-text="name"
              item-value="id"
              :items="districts"
              :rules="required"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="3">
            <label for="address">Dirección</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              autocomplete="off"
              id="address"
              v-model="lotAuction.address"
              outlined
              dense
              placeholder="Ingresa la Dirección"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="location">Buscar Ubicación</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-autocomplete
              id="location"
              v-model="selectedPlace"
              outlined
              dense
              placeholder="Ingresa la dirección"
              :loading="loadingFindAddress"
              :search-input.sync="findAddressInPlaces"
              :items="foundedAddress"
              item-text="description"
              item-value="place_id"
              :filter="filterObject"
              clearable
              return-object
              @change="getLocationByPlaceId"
              @click:clear="clearMap"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <span>Señala la ubicación del lugar de arrendamiento</span>
          </v-col>

          <v-col cols="12" md="12" class="mb-2">
            <GmapMap @click="setLocation" :center="center" :zoom="zoomMap" style="width: 100%; height: 400px">
              <GmapMarker v-if="lotAuction.location" :position="lotAuction.location" />
            </GmapMap>
          </v-col>

          <v-col cols="12" md="3">
            <label for="single_use">Único uso</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              autocomplete="off"
              id="single_use"
              v-model="lotAuction.single_use"
              outlined
              dense
              placeholder="Único uso"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="area">Área (m2)</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              autocomplete="off"
              type="number"
              id="area"
              v-model="lotAuction.area"
              outlined
              dense
              placeholder="Área"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="rent">Renta Mensual S/.</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              autocomplete="off"
              type="number"
              id="rent"
              v-model="lotAuction.rent"
              outlined
              dense
              placeholder="Renta Mensual"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="warranty">Garantía S/.</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              autocomplete="off"
              type="number"
              id="warranty"
              v-model="lotAuction.warranty"
              outlined
              dense
              placeholder="Garantía"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="advance_rent">Renta adelantada S/.</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              autocomplete="off"
              type="number"
              id="advance_rent"
              v-model="lotAuction.advance_rent"
              outlined
              dense
              placeholder="Renta adelantada"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="bid">Puja</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              autocomplete="off"
              type="number"
              id="bid"
              v-model="lotAuction.bid"
              outlined
              dense
              placeholder="Puja"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { findAddress, getContentByPlaceId } from '@/composables/useGoogleMaps'
import { createPathLotAuctions, removePathLotAuctions } from '@/composables/usePathLotAuctions'
import { mdiClose } from '@mdi/js'
export default {
  components: {
    FormTemplate,
  },
  props: {
    lotAuctionEdited: Object,
    districts: Array,
    loading: Boolean,
    idAuction: String,
    idCategoryAuction: String,
  },
  created() {
    // // console.log(this.lotAuctionEdited)
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      isNumeric: [v => !v || ''],

      url: '',
      image: null,

      lotAuction: this.lotAuctionEdited
        ? { ...this.lotAuctionEdited }
        : {
            path: [],
          },

      center: this.lotAuctionEdited
        ? this.lotAuctionEdited.location
          ? this.lotAuctionEdited.location
          : this.lotAuctionEdited.path.length > 0
          ? this.lotAuctionEdited.path[0]
          : { lat: -12.047846, lng: -77.0328566 }
        : { lat: -12.047846, lng: -77.0328566 },
      defaultCenter: { lat: -12.047846, lng: -77.0328566 },
      location: null,
      zoomMap: this.lotAuctionEdited ? 19 : 12,

      selectedPlace: null,
      loadingFindAddress: false,
      findAddressInPlaces: null,
      foundedAddress: [],
      timeOut: null,

      icons: {
        mdiClose,
      },
    }
  },
  watch: {
    findAddressInPlaces(val) {
      if (!val || val.length <= 3) return
      this.loadingFindAddress = true
      if (this.timeOut) clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.findAddressMethod(val)
        this.timeOut = null
      }, 500)
      this.loadingFindAddress = false
    },
  },
  methods: {
    validateForm() {
      return this.$refs.formLotAuction.validate()
    },
    reset() {
      this.$refs.formLotAuction.resetValidation()
    },
    async findAddressMethod(search) {
      this.foundedAddress = await findAddress(search)
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.lotAuctionEdited) {
        let DTO = { ...this.lotAuction, id_auctions: this.idAuction }
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.lotAuction, id_auctions: this.idAuction }
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
    clearMap() {
      this.center = this.defaultCenter
      this.location = null
      this.zoomMap = 12
    },
    async getLocationByPlaceId() {
      if (!this.selectedPlace) return
      const location = await getContentByPlaceId(this.selectedPlace.place_id)

      this.location = location.geometry.location
      this.center = location.geometry.location

      if (this.idCategoryAuction == 2) {
        if (this.lotAuctionEdited) {
          this.lotAuction.location = { ...this.lotAuction.location, ...location.geometry.location }
        } else {
          this.lotAuction.location = location.geometry.location
        }
      }

      this.zoomMap = 18
    },
    async removePath(idx, item) {
      if (this.lotAuctionEdited) {
        await removePathLotAuctions(item)
      }
      this.lotAuction.path.splice(idx, 1)
    },
    async addPath(location) {
      let obj = { lat: location.latLng.lat(), lng: location.latLng.lng() }
      if (this.lotAuctionEdited) {
        obj = await createPathLotAuctions({ ...obj, id_lots_auctions: this.lotAuctionEdited.id })
      }
      this.lotAuction.path.push(obj)
    },

    setLocation(location) {
      const obj = { lat: location.latLng.lat(), lng: location.latLng.lng() }
      if (this.lotAuctionEdited) {
        this.lotAuction.location = { ...this.lotAuction.location, ...obj }
      } else {
        this.lotAuction.location = obj
      }
      this.location = obj
      this.center = obj
      this.zoomMap = 18
    },
    filterObject(item, queryText, itemText) {
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #00aca0;
  padding: 20px;
}

.icon-service img {
  max-width: 80px;
  max-height: 80px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
