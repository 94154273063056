<template>
  <v-card class="card-some-auction">
    <v-img height="300px" :src="srcImage">
      <div class="button-top d-flex">
        <v-spacer></v-spacer>
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="1" v-bind="attrs" v-on="on" icon>
              <v-icon color="grey" size="20">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('edit', item)">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('remove', item)">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="content">
        <h3>{{item.districts.name}}</h3>
        <span>{{item.subtitle_some_auctions}}</span>
      </div>
    </v-img>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
export default {
  props: {
    item: Object,
    srcImage: String,
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-some-auction .content {
  padding: 40px 20px 0px 20px;
  height: 40%;
  background: linear-gradient(to top, black, rgba(255, 255, 255, 0));
}
.card-some-auction .button-top {
  height: 60%;
}

.card-some-auction h3 {
  color: white !important;
}

.card-some-auction span {
  color: white !important;
}
</style>
