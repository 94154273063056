import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useAuctionEdited = (idCategory, idAuction, router) => {

    // base data
    const loading = ref(false);

    // methods
    const refreshPage = (id_auction) => {
        router.push(`/auctions/category/${idCategory}/form/${id_auction}`);
        setTimeout(() => { router.go(); }, 1000)

    }

    const getOneAuction = async () => {
        if (idAuction) {
            loading.value = true;
            const { data, status } = await store.dispatch("fetchOneAuction", idAuction);
            loading.value = false;
            if (status != 200) return;
            return data;
        } else {
            return {};
        }
    }

    const createAuction = async (auction) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createAuction", { ...auction, id_category: idCategory })
        loading.value = false;
        if (status != 200 && status != 201) return;
        refreshPage(data.id)
    }

    const updateAuction = async (auction) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateAuction", { ...auction, id_category: idCategory })
        loading.value = false;
        if (status != 200 && status != 201) return;
    }


    return {
        loading,
        getOneAuction,
        createAuction,
        updateAuction,
    };
}

export default useAuctionEdited;