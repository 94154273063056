import { ref } from "@vue/composition-api";
import store from "@/store/index"

const useAboutUsPg = () => {

    // base data
    const districts = ref([]);

    // methods
    const getDistricts = async () => {
        const { data, status } = await store.dispatch("fetchDistricts");
        if (status != 200) return;
        districts.value = data;
        return data;
    }

    getDistricts()

    return {
        districts,
        // methods
    };
}

export default useAboutUsPg;