<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formAuction" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="2">
            <label for="file_cover_page">Banner de la Subasta</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="file_cover_page"
              v-model="image"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col v-if="url || isEdited" cols="12" class="pb-0">
            <div class="img-banner section">
              <img draggable="false" :src="url || loadImage(auction.img_cover_page)" :alt="auction.title_auctions" />
            </div>
          </v-col>

          <v-col cols="12" md="2">
            <label for="title_auctions">Nombre de la Subasta</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_auctions"
              v-model="auction.title_auctions"
              outlined
              dense
              placeholder="Nombre de la Subasta"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="end_date">Fecha de Acto Público</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              type="date"
              id="end_date"
              v-model="auction.end_date"
              outlined
              dense
              placeholder="Fecha de Finalización"
              rows="4"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="register_url">URL de Registro</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="register_url"
              v-model="auction.register_url"
              outlined
              dense
              placeholder="URL de Registro"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
          <v-col cols="12">
            <AuctionContent :idAuction="idAuction"></AuctionContent>
          </v-col>
          <v-col cols="12" class="my-4">
            <DataTableAuctionLots :idCategoryAuction="idCategoryAuction" :idAuction="idAuction"></DataTableAuctionLots>
          </v-col>
          <v-col cols="12" class="my-4">
            <ListSomeAuctions :idAuction="idAuction"></ListSomeAuctions>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import DataTableAuctionLots from '@/components/data-table/DataTableAuctionLots.vue'
import ListSomeAuctions from '@/components/lists/ListSomeAuctions.vue'
import AuctionContent from '@/views/pages/auction/AuctionContent.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    AuctionContent,
    FormTemplate,
    DataTableAuctionLots,
    ListSomeAuctions,
  },
  props: {
    idAuction: String,
    idCategoryAuction: String,
    getAuctionById: Function,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      auction: {},
      isEdited: false,
      text: '',
    }
  },
  async created() {
    this.auction = await this.getAuctionById()
    // // console.log(this.auction)
    this.isEdited = !!this.auction.id
  },
  methods: {
    validateForm() {
      return this.$refs.formAuction.validate()
    },
    reset() {
      this.$refs.formAuction.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.isEdited) {
        let DTO = { ...this.auction }

        if (this.image) {
          DTO = {
            ...DTO,
            file_cover_page: this.image,
          }
        }
        // // console.log('actualizar', DTO)
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.auction, file_cover_page: this.image }
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
.section {
  margin: 0 auto;
  max-width: 900px;
}

.img-banner img {
  max-width: 100% !important;
  max-height: 900px !important;
}

.img-banner {
  position: relative;
}
</style>
