import { ref, computed } from '@vue/composition-api';
import store from '@/store/index';

const useSomeAuctions = (idAuction) => {
    // base data
    const someAuctions = ref([]);
    const someAuctionEdited = ref(null);
    const loading = ref(false);
    const dialog = ref(false);

    // computed properties
    const titleDialog = computed(() => someAuctionEdited.value ? "Editar Promocion de Lotes" : "Agregar Promocion de Lotes");
    const subtitleDialog = computed(() =>
        someAuctionEdited.value ?
            "Rellene los campos correctamente para modificar los datos del Promocion de Lotes" :
            "Rellene los campos correctamente para agregar un nuevo Promocion de Lotes"
    );

    // methods
    const openDialog = () => {
        dialog.value = true;
    }
    const openEdit = (someAuctions) => {
        dialog.value = true;
        someAuctionEdited.value = { ...someAuctions };
    }
    const closeDialog = () => {
        dialog.value = false;
        someAuctionEdited.value = null;
    }

    // crud
    const getSomeAuctions = async () => {
        loading.value = true;
        const { data, status } = await store.dispatch('fetchSomeAuctionByAuction', idAuction);
        loading.value = false;
        if (status != 200) return;
        someAuctions.value = data;
    }

    const createSomeAuctions = async (someAuctions) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createSomeAuctions", someAuctions)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getSomeAuctions();
        closeDialog();
    }

    const updateSomeAuctions = async (someAuctions) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateSomeAuctions", someAuctions)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getSomeAuctions();
        closeDialog();
    }

    const removeSomeAuctions = async (user) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removeSomeAuctions", user)
        if (status != 200 && status != 201 && status != 204) return;
        await getSomeAuctions();
    }


    getSomeAuctions();

    return {
        someAuctions,
        loading,
        dialog,
        someAuctionEdited,
        // computed
        titleDialog,
        subtitleDialog,
        // methods
        openDialog,
        openEdit,
        closeDialog,
        // crud
        createSomeAuctions,
        updateSomeAuctions,
        removeSomeAuctions
    };
};

export default useSomeAuctions;
