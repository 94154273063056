<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formSomeAuction" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="id_districts">Distrito</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-autocomplete
              id="id_districts"
              v-model="payload['id_districts']"
              outlined
              dense
              placeholder="Distrito"
              item-text="name"
              item-value="id"
              :items="districts"
              :rules="required"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="3">
            <label for="subtitle_some_auctions">Subtítulo</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="subtitle_some_auctions"
              v-model="payload['subtitle_some_auctions']"
              outlined
              dense
              placeholder="Desde S/. 1,000 por mes"
              :autocomplete="autocomplete"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="file-input-reel">Imagen</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file-input-reel"
              v-model="image"
              outlined
              dense
              placeholder="Selecciona una imagen"
              :autocomplete="autocomplete"
              :rules="payloadEdited ? [] : required"
              chips
            ></v-file-input>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
export default {
  components: {
    FormTemplate,
  },
  props: {
    payloadEdited: Object,
    idAuction: String,
    districts: Array,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      autocomplete: 'off',
      isEdited: this.payloadEdited ? true : false,
      image: null,
      payload: this.payloadEdited ? { ...this.payloadEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formSomeAuction.validate()
    },
    reset() {
      this.image = null
      this.$refs.formSomeAuction.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      // // console.log(this.payload, this.idAuction)
      const formData = new FormData()
      formData.append('subtitle_some_auctions', this.payload['subtitle_some_auctions'])
      formData.append('id_auctions', this.idAuction)
      formData.append('id_districts', this.payload['id_districts'])
      if (this.image) {
        formData.append('file_some_auctions', this.image)
      }
      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      const DTO = {
        id: this.payload.id,
        formData,
      }
      if (this.isEdited) {
        this.$emit('beforeUpdate', DTO)
      } else {
        this.$emit('beforeCreate', DTO)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
