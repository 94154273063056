import store from "@/store/index"

export const createPathLotAuctions = async (item) => {
    const { data, status } = await store.dispatch("createPathLotAuction", item);
    return data;
}

export const removePathLotAuctions = async (item) => {
    const { data, status } = await store.dispatch("removePathLotAuction", item);
    return data;
}

