import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useLotsAuction = (idAuction) => {

    // base data
    const dialog = ref(false);
    const lotAuctionEdited = ref(null);
    const lotsAuction = ref([]);
    const loading = ref(false);

    // computed properties
    const titleDialog = computed(() =>
        lotAuctionEdited.value ? "Editar Lote de Subasta" : "Agregar Lote de Subasta"
    );
    const subtitleDialog = computed(() =>
        lotAuctionEdited.value ?
            "Rellene los campos correctamente para modificar los datos del Lote de Subasta" :
            "Rellene los campos correctamente para validar los datos del Lote de Subasta"
    );

    const closeDialog = () => {
        dialog.value = false;
        lotAuctionEdited.value = null;
    }

    // methods
    const getLotsAuctionList = async () => {
        const { data, status } = await store.dispatch("fetchLotsAuctionByIdAuction", idAuction)
        if (status != 200) return;
        lotsAuction.value = data;
    }

    const createLotAuction = async (lotAuction) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createLotAuction", lotAuction)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getLotsAuctionList();
        closeDialog();
    }

    const updateLotAuction = async (lotAuction) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateLotAuction", lotAuction)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getLotsAuctionList();
        closeDialog();
    }

    const removeLotAuction = async (lotAuction) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removeLotAuction", lotAuction)
        if (status != 200 && status != 201 && status != 204) return;
        await getLotsAuctionList();
    }

    getLotsAuctionList();

    return {
        dialog,
        lotAuctionEdited,
        lotsAuction,
        loading,
        // computed
        titleDialog,
        subtitleDialog,
        // methods
        openDialog() {
            dialog.value = true;
        },
        openEdit(lotAuction) {
            let location = lotAuction.location
            const path = lotAuction.path.map((p) => {
                const obj = p;
                obj.lat = +obj.lat;
                obj.lng = +obj.lng;
                // console.log(obj);
                return obj;
            })
            if (!!location) {
                location = {
                    ...location,
                    lat: +location.lat,
                    lng: +location.lng
                }
            }
            lotAuctionEdited.value = { ...lotAuction, path, location };
            dialog.value = true;

        },
        openRemove: removeLotAuction,
        closeDialog,
        createLotAuction,
        updateLotAuction
    };
}

export default useLotsAuction;
